body {
    background-color: #0c326d;
    background-image: linear-gradient(to top,#19d07c 0,rgba(35,168,236,0) 80%);

    // background-color: var(--tg-theme-bg-color);
    // background-image: var(--tg-theme-bg-color);
    // color: var(--tg-theme-text-color);
    color: #000;
    font-family: sans-serif;
    min-height: 100vh;

    max-width: 600px;
    margin: 0 auto;
}

.App.fullscreen .page-content.success {
    min-height: calc(100vh - 265px);
  }

.App__loader {
    min-height: calc(100vh - 240px);
    display: flex;
    justify-content: center;
}

.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
    margin-top: 20vh;
}
.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.header {
    position: relative;

    &__container {
        height: 50px;
        width: 100%;
        background: #fff;
        color: #333;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
    }

    &__container.active {
        position: fixed;
        z-index: 99;
        top: 0;
    }

    &__menu.active {
        margin-top: 50px;
    }

    &__menu-btn {
        position: relative;
        width: 30px;
        height: 20px;
        cursor: pointer;
        margin-left: 20px;


        // opacity: 0;
        // pointer-events: none;
    }

    &__menu-btn::before {
        top: 0;
    }

    &__menu-btn span {
        top: 50%;
        margin-top: -2px;
    }

    &__menu-btn::after {
        top: 16px;
    }

    &__menu-btn span,
    &__menu-btn::before,
    &__menu-btn::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        height: 4px;
        background-color: #2D73ED;
    }
    
    &__logo img {
        width: 140px;
    }

    &__cart-btn img {
        width: 25px;
        position: absolute;
        z-index: 0;
        margin-left: -11px;
        top: 15px;
    }

    &__cart-text {
        background-color: #2D73ED;
        font-size: 12px;
        font-weight: 700;
        border-radius: 2px;
        padding: 0 4px;
        color: #fff;
        line-height: 18px;
        z-index: 1;
        position: relative;
        margin-top: 10px;
    }

    &__cart-btn {
        cursor: pointer;
        margin-right: 20px;
        display: block;
        height: 40px;
        position: relative;
        text-decoration: none;
    }

    &__menu {
        background: #fff;
        color: initial;
    }

    &-menu__top {
        margin-top: 5px;
    }

    &__menu-content {
        display: flex;
        flex-direction: column;
        height: calc(100vh - 50px);
        justify-content: space-between;
        position: fixed;
        height: 100%;
        width: 100%;
        background: #fff;
        z-index: 999;
        overflow: scroll;
    }

    &-menu__item {
        font-size: 15px;
        font-weight: 700;
        padding: 13px 20px;
        text-transform: uppercase;
        display: block;
        color: #000;
        text-decoration: none;
    }

    &-menu__bottom a {
        // color: #fff;
        text-decoration: none;
    }
    
    &-menu__bottom {
        background: #161616;
        color: #fff;
    }


    &-menu__phone a {
        color: #000;
    }
    &-menu__phone {
        background: #fff;
        color: #333;
        border-top: 1px solid #e8ecf1;
        padding: 15px 20px;
        font-size: 20px;
    }

    &-menu__phone-sub {
        font-size: 13px;
        line-height: 1.38;
        margin-bottom: 10px;
    }

    &-menu__bottom-links a {
        padding: 15px 0;
        width: 100%;
        display: block;
        color: #fff;
        font-size: 15px;
        font-weight: 700;
        text-transform: uppercase;
    }

    &-menu__bottom-links li {
        padding: 0 20px;
    }

    &-menu__socials img {
        width: 24px;
        margin-right: 5px;
        filter: invert(1);
    }

    &-menu__socials {
        display: flex;
        padding-left: 20px;
        margin-bottom: 20px;
    }

    &-menu__copy-text {
        border-top: 1px solid #262626;
        color: #645d5d;
        font-size: 13px;
        padding: 14px 20px;
    }

    &-menu__bottom-links {
        padding-top: 10px;
    }
}

.content {
    &__items {
        display: flex;
        flex-wrap: wrap;
        color: initial;
    }

    &__item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: calc(50% - 23px);
        background: #fff;
        border: 1px solid #fff;
        margin: 10px;
        color: initial;
        padding-top: 10px;
        text-decoration: none;

        i.item-arrow {
            display: inline-block;
        }

        i.item-arrow svg {
            width: 14px;
            opacity: 0.6;
        }
    }

    &__item-title {
        color: initial;
        font-size: 13px;
        font-weight: 600;
        padding: 3px 10px;
        display: flex;
        align-items: center;
        line-height: 12px;
    }

    &__item-img {
        display: block;
        max-width: 100%;
        margin: 0 auto;
        height: 90px;
        background-size: contain !important;
        background-position: center !important;
        width: 100%;
        background-repeat: no-repeat !important;
    }

}

.footer {
    background: #161616;
    color: #fff;
    // padding: 0 20px;

    a {
        text-decoration: none;
        // color: #fff;
    }

    img {
        filter: invert(1);
    }

    &__phone {

    }

    &__phone-item {
        color: #2D73ED;
        font-weight: 600;
        font-size: 17px;
        display: block;
    }

    &__phone-sub {
        margin-top: 0px;
        margin-bottom: 24px;
        font-size: 13px;
        line-height: 1.38;
    }

    &__bottom {
        display: flex;
        justify-content: space-between;

        padding: 32px 20px 33px;
        border-top: 1px solid #adadad;
    }

    &__socials {
        display: flex;
        flex-direction: column;
    }

    &__socials img {
        width: 25px;
        margin-bottom: 10px;
    }

    &__copy {
        font-weight: 300;
        font-size: 13px;
        text-align: right;
        padding: 0 20px 10px;
    }
}

.page {
    &-content {
        min-height: 71vh;
        background: #ebebeb;
    }

    &-content.product {
        background: #fff;
    }

    &__breadcrubms a {
        text-decoration: none;
        color: #6F6F6F;
    }
    
    &__breadcrubms {
        font-size: 13px;
        color: #aaaeb5;
        padding: 10px 20px 0;
        line-height: 1.38;
    }

    &__title {
        font-size: 24px;
        text-align: center;
        font-weight: 700;
        margin-bottom: 34px;
        margin-top: 20px;
    }
}

.category {
    &__item {
        background: #fff;
        margin: 0 20px 20px;
        display: flex;
        flex-wrap: wrap;
        position: relative;

        &--link {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 10;
        }

        .item__content {
            width: calc(100% - 110px);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .item__bottom {
            width: 100%;
            margin-top: 20px;
            border-top: 1px solid #ededed;
            display: flex;
            justify-content: space-between;
        }

        .item__image {
            width: 100px;
            height: 100px;
            object-fit: cover;
            margin-right: 10px;
        }

        .item__title {
            color: #00a2ff;
            font-size: 15px;
            font-weight: 600;
            line-height: 1.33;
            margin: 10px 0;
        }

        .item__props {
            display: flex;
            width: 100%;
            padding-bottom: 10px;
        }

        .item__icon--text {
            background: #c9d4e3;
            width: 18px;
            height: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100%;
            color: #fff;
            font-size: 12px;
            font-weight: bold;
        }

        .item__prop--text {
            display: flex;
            color: #151515;
            font-size: 13px;
            margin-right: 10px;
            align-items: center;
        }

        .item__prop-text {
            max-width: 50px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
        }

        i.item__icon {
            margin-right: 5px;
        }

        i.item__icon img {
            width: 18px;
            height: 18px;
        }

        .item__price {
            padding: 16px 0 20px 24px;
            font-size: 18px;
            // font-weight: 600;
            letter-spacing: -0.8px;
        }

        .item__button {
            background-image: url("data:image/svg+xml,%3C!-- Generator: Adobe Illustrator 23.0.1, SVG Export Plug-In --%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='33.9px' height='22px' viewBox='0 0 33.9 22' style='enable-background:new 0 0 33.9 22;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23C8D3E1;%7D%0A%3C/style%3E%3Cdefs%3E%3C/defs%3E%3Cg%3E%3Cpath class='st0' d='M14.4,19.6c0,1.3,1.1,2.4,2.4,2.4c1.3,0,2.4-1,2.5-2.4c0,0,0,0,0,0c0-1.4-1.1-2.5-2.5-2.5S14.4,18.2,14.4,19.6 z M27.4,19.6c0,1.3,1.1,2.4,2.5,2.4c1.3,0,2.4-1.1,2.4-2.4c0-1.3-1.1-2.4-2.4-2.5c0,0,0,0,0,0C28.6,17.1,27.5,18.2,27.4,19.6 L27.4,19.6z M32.7,3H14.4l-0.8-2.3C13.4,0.3,13,0,12.6,0H10C9.5,0,9,0.4,9,1s0.4,1,1,1h1.9l4.8,12.9c0.2,0.4,0.6,0.7,1.1,0.6 C31.2,13.9,31,13.7,31,13.7c0.4,0,0.8-0.4,0.9-0.8l1.9-8.7c0.1-0.3,0-0.6-0.2-0.8C33.5,3.1,33.2,3,32.7,3z M3,8v2H1 c-0.6,0-1,0.4-1,1s0.4,1,1,1h2v2c0,0.6,0.4,1,1,1s1-0.4,1-1v-2h2c0.6,0,1-0.4,1-1s-0.4-1-1-1H5V8c0-0.6-0.4-1-1-1S3,7.4,3,8z'/%3E%3C/g%3E%3C/svg%3E%0A");
            border: 0;
            width: 60px;
            background-color: #24be77;
            background-position: center;
            background-repeat: no-repeat;
            z-index: 11;
            cursor: pointer;

            background-blend-mode: multiply;
        }

        .item__button:focus, .item__button:hover {
            background-blend-mode: color-dodge;
        }
    }
}
.product {
    &__breadcrumbs a {
        text-decoration: none;
        color: #6F6F6F;
    }

    &__breadcrumbs a:after {
        content: ' / ';
    }
    &__breadcrumbs {
        font-weight: 350;
        font-size: 12px;
        line-height: 16px;
        color: #6F6F6F;
        padding: 16px 20px 0;
    }
}


.no-token {
    &__container {
        display: flex;
        flex-direction: column;
        min-height: 50vh;
        justify-content: center;
        padding: 0 25px;
        font-size: 15px;
        color: #fff;
    }

    &__button {
        width: 250px;
        border: 0;
        height: 40px;
        border-radius: 3px;
        font-size: 13px;
        display: block;
        margin: 20px auto 0;
    }
}

.product {
    &__top {
        margin-top: 33px;
        display: flex;
        justify-content: space-between;
        padding: 0 16px;
    }

    &__top--price {
        font-size: 24px;
        line-height: 32px;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }

    &__counter {
        display: flex;
        color: #A3A3A3;
        font-size: 30px;
    }

    &__counter--char {
        width: 30px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    &__counter--num {
        display: flex;
        width: 70px;
        height: 20px;
        justify-content: center;
        align-items: center;
        color: #000;
        border: 0;
        font-weight: bold;
        font-size: 18px;
        outline: none;
        text-align: center;
        border-bottom: 1px solid #000;
    }

    &__top--title {
        color: #A3A3A3;
        font-size: 17px;
        line-height: 22px;
        letter-spacing: -0.01em;
        margin-top: -5px;
    }

    &__cart-add {
        display: block;
        width: 100%;
        background: #24be77;
        border: 0;
        text-transform: uppercase;
        color: #fff;
        height: 56px;
        font-weight: 600;
        margin-top: 16px;
        cursor: pointer;
    }

    &__cart-add:hover,
    &__cart-add:focus {
        background: #1f8153;
    }

    &__content {
        padding: 0 16px;
    }

    &__image img {
        max-width: 100%;
    }

    &__tab-links {
        display: flex;
        padding: 24px 0 22px;
    }

    &__tab-link {
        font-size: 16px;
        line-height: 21px;
        letter-spacing: -0.01em;
        white-space: nowrap;
        color: #A3A3A3;
        border: 0;
        margin-right: 24px;
        background: none;
        margin-bottom: 1px;
        cursor: pointer;
    }

    &__tab-link.active {
        color: #2D73ED;
        border-bottom: 1px solid #2D73ED;
        margin-bottom: 0;
    }

    &__tab--params li {
        display: flex;
        justify-content: space-between;
        font-size: 13px;
        line-height: 18px;
        font-weight: bold;
        margin-bottom: 17px;
    }

    &__tab-divider {
        flex-grow: 0.95;
        border-bottom: 1px solid #D8DEE8;
    }

    &__tab--description h1,
    &__tab--description h2,
    &__tab--description h3,
    &__tab--description p,
    &__tab--description ul,
    &__tab--description li {
        margin: 5px 0 5px 0;
        padding: revert;
        border: revert;
        font-size: revert;
        vertical-align: initial;
        list-style: initial;
    }

    &__tab--description p {
        padding-top: 10px;
    }

    &__tab-content {
        padding-bottom: 20px;
    }
}

.category {
    padding-bottom: 20px;
}

.cart {
    &__success {
        position: fixed;
        z-index: 9999;
        background: rgba(0,0,0,0.5);
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__success-content {
        display: flex;
        flex-direction: column;
        background: #fff;
        width: 90vw;
        text-align: center;
        padding: 40px 0;
        position: relative;
    }

    &__success-close {
        position: absolute;
        top: 15px;
        right: 15px;
        border: 0;
        background: #dde2ea;
        width: 20px;
        height: 20px;
        padding: 0;
        border-radius: 100%;
        color: #fff;
    }

    &__success-title {
        font-size: 24px;
        font-weight: 700;
        line-height: 1.17;
        padding-bottom: 43px;
    }

    &__success-nav {
        background-color: #24be77;
        color: #fff;
        font-size: 15px;
        font-weight: 700;
        line-height: 1;
        text-transform: uppercase;
        text-decoration: none;
        padding: 21px 30px 24px;
        margin: 0 20px 20px;
    }

    &__success-continue {
        color: #2D73ED;
        font-size: 15px;
        font-weight: 400;
        line-height: 1.38;
        border: 0;
        margin: 0 20px;
        background: 0;
    }
}

.cart {
    &-page__title {
        font-size: 24px;
        text-align: center;
        padding-top: 20px;
        font-weight: bold;
        margin-bottom: 34px;
    }

    &-item__arrows-plus img, 
    &-item__arrows-minus img {
        width: 18px;
        height: 10px;
        object-position: center;
    }

    &-item__arrows-plus,
    &-item__arrows-minus {
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg enable-background='new 0 0 14 8' version='1.1' viewBox='0 0 14 8' xml:space='preserve' xmlns='http://www.w3.org/2000/svg'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%230E0F0F;%7D%0A%3C/style%3E%3Cpath class='st0' d='m2.2 7.9 4.8-4.9 4.8 4.8 1.4-1.4-6.2-6.2-6.2 6.2 1.4 1.5z'/%3E%3C/svg%3E%0A");
        width: 20px;
        height: 20px;
        background-position: 50%;
        background-size: 90%;
        background-repeat: no-repeat;
        background-color: transparent;
    }

    &-item__arrows-minus img,
    &-item__arrows-minus {
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg enable-background='new 0 0 14 8' version='1.1' viewBox='0 0 14 8' xml:space='preserve' xmlns='http://www.w3.org/2000/svg'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%230E0F0F;%7D%0A%3C/style%3E%3Cpath class='st0' d='M11.8,0.2L7,5L2.1,0.2L0.7,1.7l4.9,4.8L7,7.8l6.2-6.3L11.8,0.2z'/%3E%3C/svg%3E%0A");
    }

    &-item__image {
        width: 120px;
        height: 80px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    &-item {
        box-shadow: 1px 1px 6px rgb(177 177 177);
        position: relative;
        margin-bottom: 20px;
        background: #fff;
        padding-bottom: 5px;
    }

    &-item__top {
        display: flex;
        align-items: center;
    }

    &-item__remove {
        position: absolute;
        color: #333;
        top: 10px;
        right: 10px;
        border: 0;
        background: none;
    }

    &-item__title {
        font-size: 15px;
        font-weight: 600;
        line-height: 1.33;
        padding-left: 20px;
        padding-right: 30px;
    }

    &-item__counter {
        width: 115px;
        height: 50px;
        background: #333;
        display: flex;
        border-radius: 4px;
        background-color: #eaeef4;
        font-size: 17px;
        justify-content: space-between;
        margin: 0 15px 0 5px;
    }

    &-item__bottom {
        margin-top: 20px;
        border-top: 1px solid #e8ecf1;
        padding-top: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &-item__bottom--counter {
        display: flex;
        align-items: center;
        // margin-left: 5px;
    }

    &-item__counter-num {
        width: 80px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 300;
        outline: none;
        border: 0;
        background: #eaeef4;
        text-align: center;
        font-size: 16px;
        line-height: 0;
        padding: 0;
      }

    &-item__counter-arrows {
        width: 33px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        filter: opacity(0.5);
    }

    &-item__arrows-plus, 
    &-item__arrows-minus {
        border: 0;
        // background: none;
    }

    &-item__summ {
        font-size: 17px;
        font-weight: 600;
        margin-right: 20px;
    }
    
    &-page__items {
        margin-bottom: 30px;
        border-bottom: 1px solid #d9dfe8;
        padding-bottom: 30px;
    }

    &-page__form-title {
        color: #000b19;
        font-size: 20px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: .4px;
        margin-bottom: 20px;
    }

    &-page__form-input {
        width: calc(100% - 20px);
        height: 56px;
        border-radius: 4px;
        background-color: #eaeef4;
        color: #000;
        font-size: 15px;
        border: 0;
        outline: none;
        padding-left: 20px;
        font-weight: 300;
        margin-bottom: 20px;
        transition: .3s all;
        z-index: 2;
        position: relative;
    }

    .cart-page__form-input.error {
        border: 2px solid red;
    }
    
    .user__form {
        margin-bottom: 30px;
        border-bottom: 1px solid #d9dfe8;
        padding-bottom: 30px;
    }

    &-page__form-input:last-child {
        margin-bottom: 0;
    }

    &-page__user {
        padding-bottom: 10px;
    }

    &-page__form-checkbox {
        font-size: 11px;
        color: #000b19;
        font-weight: 300;
        text-transform: uppercase;
        letter-spacing: .3px;
        position: relative;
        margin-bottom: 25px;
        line-height: 21px;
    }

    .user__form input[type="checkbox"] {
        float: left;
        margin-left: 0;
    }

    &-page__confirm {
        width: 100%;
        font-size: 20px;
        font-weight: 600;
        background-color: #24be77;
        padding: 18px 57px 21px;
        display: inline-block;
        vertical-align: top;
        border: 0;
        color: #fff;
        cursor: pointer;
        transition: .3s all;
    }

    &-page__confirm:hover {
        background: #1f8153;
    }

    // &-page__confirm:focus
    &-page__confirm.disabled {
        background: #9d9d9d;
        pointer-events: none;
      }

    select.cart-page__form-input {
        width: 100%;
    }

    .user__form-summary {
        display: flex;
        justify-content: space-between;
        font-size: 28px;
        margin-top: 20px;
    }
}

.page-content.cart {
    background: #fff;
    padding: 0 20px;
}
   
@keyframes placeHolderShimmer{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}
   
.anim-loader .cart-item:before {
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: darkgray;
    background: linear-gradient(to right, #eeeeee 10%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px 104px;
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 1;
    z-index: 9;
}

.order {
    padding: 20px 20px 20px;

    &__item {
        display: flex;
        padding: 20px 30px;
        background: #fff;
        margin-bottom: 10px;
        justify-content: space-between;
        font-size: 17px;
    }

    &__status {
        font-weight: bold;
    }


}

.page-content.success h2 {
    font-size: 26px;
    font-weight: bold;
    line-height: 40px;
}
  

.page-content.success h3 {
    font-size: 18px;
    line-height: 19px;
}

.page-content.success {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-align: center;
    padding: 30px 20px;
}

.cart__loader {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    left: 0;
    top: 0;
    background: rgba(0,0,0,0.5);
    z-index: 999;

    .lds-dual-ring {
        margin-top: calc(50vh - 65px);
    }
}

.order__id {
    text-decoration: underline;
    cursor: pointer;
}

.order-popup {
    position: fixed;
    top: 0;
    margin-left: -20px;
    width: 100%;
    height: 100%;
    max-width: 600px;
    background: rgba(0,0,0,0.6);
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;

    &__content {
        width: 100%;
        margin: 20px;
        background: #fff;
        border-radius: 5px;
        max-width: 500px;
        padding: 5px 10px;
        position: relative;
        overflow: auto;
        max-height: 90vh;
    }

    &-close {
        cursor: pointer;
        position: absolute;
        top: 15px;
        right: 15px;
        border: 0;
        background: #dde2ea;
        width: 20px;
        height: 20px;
        padding: 0;
        border-radius: 100%;
        color: #fff;
        text-align: center;
        font-size: 30px;
        line-height: 17px;
    }

    &__title {
        padding-top: 35px;
        display: flex;
        justify-content: space-between;
        font-size: 18px;
        font-weight: bold;
        padding-bottom: 20px;
        margin: 0 20px;
    }

    &__item {
        border-bottom: 1px solid #e0e0e0;
        padding-bottom: 10px;
        line-height: 19px;
        margin: 0 20px 10px;
    }

    &__sum {
        margin: 20px 20px 20px;
        font-weight: bold;
        font-size: 18px;
    }

    &__item-title {
        font-size: 16px;
        font-weight: bold;
    }
}

.cart-page {
    &__form-container {
        position: relative;
        z-index: 4;
    }

    &__form-dropdown {
        position: absolute;
        top: 56px;
        background: #eaeef4;
        width: 100%;
        overflow: auto;
        max-height: 400px;
        border: 1px solid #cdc7c7;
        border-radius: 0 0px 3px 3px;
        z-index: 0;
    }

    &__form-dropdown-item:last-child {
        border-bottom: none;
    }

    &__form-dropdown-item {
        padding: 15px 20px;
        border-bottom: 1px solid #cdc7c7;
        transition: .3s all;
        cursor: pointer;
    }

    &__form-dropdown-item:hover {
        background: #d9d9d9;
    }
}

.order-popup__info h3 {
    font-weight: bold;
}
.order-popup__info {
    padding: 10px 20px 0;
    line-height: 20px;
}

.cart-page__form-delivery {
    text-align: right;
    line-height: 25px;
    font-size: 18px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none; // Yeah, yeah everybody write about it
}

input[type='number'],
input[type="number"]:hover,
input[type="number"]:focus {
    appearance: none;
    -moz-appearance: textfield;
}

.header-menu__list {
    border-top: 1px solid #e1e1e1;
}

.item__price--new {
    color: #ff3c33;
}

.item__price--old::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background: #787373;
    left: 0;
    top: 39%;
}
.item__price--old {
    color: #787373;
    margin-bottom: 0px;
    position: relative;
    font-size: 16px;
    margin-left: 10px;
}

.product__top--price .item__price--old {
    font-size: 20px;
}